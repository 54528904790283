import React from "react";
import { NavLink } from "react-router-dom";
import { AiFillCar, AiFillDashboard } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { GiPathDistance } from "react-icons/gi";
import { FiPackage } from "react-icons/fi";
import { FaIndustry } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import { IoCreateOutline } from "react-icons/io5";

export default function NavBar() {
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <NavLink
          to="/"
          className="brand-link removeline"
          style={{ textDecoration: "none" }}
        >
          <img
            src="/dist/img/AdminLTELogo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="font-weight-light removeline hide-when-collapse">
            SOS Admin
          </span>
        </NavLink>

        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <NavLink to="/dashboard" className="nav-link">
                  <i className="nav-icon far ">
                    <AiFillDashboard />
                  </i>
                  <p>Dashboard</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/job" className="nav-link">
                  <i className="nav-icon far ">
                    <IoCreateOutline />
                  </i>
                  <p>Create Order</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas ">
                    <BiUser />
                  </i>
                  <p>
                    User
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/user" className="nav-link">
                      <GoPrimitiveDot className="mx-2" />
                      <p>Users</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/adduser" className="nav-link">
                      <GoPrimitiveDot className="mx-2" />
                      <p>Invite Special User</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/specialUser" className="nav-link">
                      <GoPrimitiveDot className="mx-2" />
                      <p>Special User</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <NavLink to="/driver" className="nav-link">
                  <i className="nav-icon far ">
                    <AiFillCar />
                  </i>
                  <p>Drivers</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas ">
                    <FiPackage />
                  </i>

                  <p>
                    Jobs
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/package/new" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">New</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/package/onGoing" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">Active</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/package/history" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">Completed</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas ">
                    <FaIndustry />
                  </i>

                  <p>
                    Company
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/addCompany" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">Add company</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/companyList" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">Companies</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              {/* <li className="nav-item">
                <a href="#" className="nav-link">
                  <i className="nav-icon fas ">
                    <GiPathDistance />
                  </i>

                  <p>
                    Price Range
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/addPriceRange" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">Add</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/priceRange" className="nav-link">
                      <GoPrimitiveDot className="ml-2" />
                      <p className="ml-2">List</p>
                    </NavLink>
                  </li>
                </ul>
              </li> */}

              <li className="nav-item">
                <NavLink to="/priceRange" className="nav-link">
                  <i className="nav-icon far ">
                    <GiPathDistance />
                  </i>
                  <p>Price Range</p>
                </NavLink>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
      {/* Content Wrapper. Contains page content */}
    </div>
  );
}
