//export const baseUrl = "http://3.141.68.7:5000";
//export const baseUrl = "http://localhost:5000";
export const baseUrl = "https://api.stayonsite.com.au";

export const awsUrl = "https://sosbucket.s3.us-east-2.amazonaws.com/oderImage/";

export const userPerPage = 10;

export const openModel = {
  ASSIGN_DRIVER: 1,
  DRIVER_LOC: 3,
  NON: 2,
  ADD_ADDRESS: 4,
  CHANGE_COMPANY: 5,
  EDIT_ADDRESS: 6,
  CHANGE_PRICE_RANGE: 7,
  VIEW_IMAGE: "view image",
};

export const PICKUP_TIME = [
  "07:00 am",
  "07:30 am",
  "08:00 am",
  "08:30 am",
  "09:00 am",
  "09:30 am",
  "10:00 am",
  "10:30 am",
  "11:00 am",
  "11:30 am",
  "12:00 pm",
  "12:30 pm",
  "01:00 pm",
  "01:30 pm",
  "02:00 pm",
  "02:30 pm",
  "03:00 pm",
];

export const deliveryTypes = {
  ANY_TIME: "Any Time",
  URGENT: "Urgent",
  ANY_FUTURE_DAY: "Any Future Day",
};
