import React, { useState } from "react";

import { login } from "../networkRequests/admin";
import { loading, toastError } from "../utlis/common";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const _login = async (e) => {
    e.preventDefault();
    if (!email) {
      return toastError("Please enter email");
    }
    if (!password) {
      return toastError("Please enter password  ");
    }
    try {
      setLoader(true);
      const res = await login(email, password);
      localStorage.setItem("accessToken", res.data.token);
      window.location.reload();
      //setLoader(false);
    } catch (error) {
      setLoader(false);
      toastError(error.data.message);
      console.log(error);
    }
  };

  return (
    <div id="app">
      {loader ? loading : ""}
      <nav className="navbar navbar-expand-md navbar-light bg-white shadow-sm">
        <div className="container">
          <a className="navbar-brand">
            <img
              src="/dist/img/sos-logo-white 1.png"
              style={{ opacity: "0.8" }}
              alt="AdminLTE Logo"
              className="brand-image  "
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Left Side Of Navbar */}
            <ul className="navbar-nav mr-auto"></ul>
          </div>
        </div>
      </nav>
      <main className="py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">Login</div>
                <div className="card-body">
                  <form>
                    {/* <input
                      type="hidden"
                      name="_token"
                      defaultValue="vOdE3I89ZsxqsbmGk8ukDwBdplXyJmZHd1muM6CV"
                    /> */}
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label text-md-right">
                        E-Mail Address
                      </label>
                      <div className="col-md-6">
                        <input
                          required
                          type="email"
                          className="form-control "
                          autofocus
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="password"
                        className="col-md-4 col-form-label text-md-right"
                      >
                        Password
                      </label>
                      <div className="col-md-6">
                        <input
                          type="password"
                          required
                          autofocus
                          className="form-control"
                          placeholder="password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-md-6 offset-md-4">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="remember"
                            id="remember"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="remember"
                          >
                            Remember Me
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row mb-0">
                      <div className="col-md-8 offset-md-4">
                        <button
                          type="submit"
                          className="btn btn-primary yellowbutton"
                          onClick={_login}
                        >
                          Login
                        </button>
                        {/* <a
                          className="btn btn-link black"
                          href="http://3.131.252.115/password/reset"
                        >
                          Forgot Your Password?
                        </a> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
