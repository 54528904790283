import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";

import { customStyles, toastError, toastSuccess } from "../../utlis/common";
import { loading } from "../../utlis/common";
import {
  getUsers,
  getUserAddresses,
  createPackage,
} from "../../networkRequests/package";
import { getCompanies } from "../../networkRequests/comapny";
import { useModal } from "../../context/modalState";
import { openModel, PICKUP_TIME } from "../../utlis/appConstants";
import AddAddress from "../modals/AddAddress";
import { calculateAmount } from "../../utlis/calculateAmount";
import { getDistance } from "../../networkRequests/admin";
import { priceRangeAmount } from "../../networkRequests/priceRange";

export default function CreateJob() {
  const history = useHistory();
  const { modelState, setModelState } = useModal();
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [userId, setUserId] = useState("");
  const [notes, setNotes] = useState("");
  const [dropAddress, setDropAddress] = useState({});
  const [pickupAddress, setPickUpAddress] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [startTime, setStartTime] = useState("07:00 am");
  const [pickupDate, setPickupDate] = useState(new Date());
  const [quantity, setQuantity] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [description, setDescription] = useState("");
  const [packageItems, setPackageItems] = useState([]);
  const [userType, setUserType] = useState();
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [deliverType, setDeliverType] = useState("");
  const [estimatePrice, setEstimatePrice] = useState(0);
  const [changePrice, setChangePrice] = useState(false);
  const [deliveryMessage, setDeliveryMessage] = useState("");
  const [addressType, setAddressType] = useState("");
  const [dropNotes, setDropNotes] = useState("");
  const [willDelivered, setwillDelivered] = useState("");
  const [deliveryDate, setDeliveryDate] = useState();

  const _userList = async () => {
    try {
      const res = await getUsers(
        userType,
        userType == "User" ? "" : selectedCompanyId
      );

      setUsers(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _createOrder = async () => {
    if (!userType) {
      return toastError("Please select user type");
    }
    if (!userId) {
      return toastError("Please select user");
    }

    // if (!orderNumber) {
    //   return toastError("Please enter purchase order number");
    // }

    if (estimatePrice == 0) {
      return toastError("Please enter estimate price");
    }

    if (!pickupAddress.title) {
      return toastError("Please select pickup address");
    }
    if (!dropAddress) {
      return toastError("Please select drop address");
    }

    if (!startTime) {
      return toastError("Please select pickup time.");
    }

    if (dropAddress == pickupAddress) {
      return toastError("Pickup and drop address are same.");
    }
    // if (phoneNumber) {
    //   if (
    //     isNaN(phoneNumber) ||
    //     phoneNumber.length > 10 ||
    //     phoneNumber.length < 10
    //   ) {
    //     return toastError("Please enter a valide phone number");
    //   }
    // }
    if (userType === "User" && !packageItems.length) {
      return toastError("Please add package items");
    }
    if (userType === "Special user" && !deliverType) {
      return toastError("Please select delivery type.");
    }

    if (estimatePrice === 0) {
      return toastError("Please enter estimate price");
    }

    if (
      moment(startTime, "hh:mm a").format("HH:mm") <=
        moment().format("HH:mm") &&
      moment(pickupDate).format("YYYY-MM-DD") ==
        moment(new Date()).format("YYYY-MM-DD")
    ) {
      return toastError("Please select upcomming pickup time");
    }
    //estimate Price
    let data = {
      orderNumber,
      userId,
      notes,
      dropNotes,
      dropAddress: { ...dropAddress, addressId: dropAddress._id },
      pickupAddress: { ...pickupAddress, addressId: pickupAddress._id },
      phoneNumber,
      email,
      pickupDate: moment(pickupDate).format("L"),
      pickupTime: startTime,
      price: estimatePrice,
      willDelivered,
      deliveryDate,
      deliverType,
    };

    data["packageItems"] = packageItems.map((items) => {
      return {
        quantity: items.quantity,
        length: items.length,
        width: items.width,
        height: items.height,
        weight: items.weight,
        description: items.description,
      };
    });

    try {
      setLoader(true);
      const res = await createPackage(data);
      setQuantity("");
      setLength("");
      setWidth("");
      setHeight("");
      setWeight("");
      setUserId("");
      setDescription("");
      setNotes("");
      setDropNotes("");
      setDropAddress({});
      setPickUpAddress({});
      setPhoneNumber("");
      setEmail("");
      setStartTime(moment().add(30, "minutes").format("LT"));
      setPickupDate(new Date());
      setPackageItems([]);
      setUserType("");
      setOrderNumber("");
      setEstimatePrice(0);
      toastSuccess("Order Successfully created");
    } catch (error) {
      setLoader(false);
      console.log(error);
      toastError(error.data.message);
    }
    return setLoader(false);
  };

  const _addItem = async () => {
    if (!quantity) {
      return toastError("Please enter quantity");
    }
    if (!length || !width || !height || !weight) {
      return toastError("Please enter valide dimensions");
    }
    setPackageItems([
      ...packageItems,
      {
        id: new Date().getTime(),
        quantity,
        length,
        width,
        height,
        weight,
        description,
      },
    ]);
    setQuantity("");
    setLength("");
    setWidth("");
    setHeight("");
    setWeight("");
    setDescription("");
  };

  const _getAddresses = async () => {
    try {
      const res = await getUserAddresses(userId);

      setAddresses(res.data.addresses);
    } catch (error) {
      console.log(error);
    }
  };

  const _getCompanies = async () => {
    try {
      const res = await getCompanies(0, 1000);
      setCompanies(res.data.companies);
    } catch (error) {
      toastError(error.data.message);
    }
  };

  const calculatePrice = async () => {
    let totalDistance = 0;

    try {
      setLoader(true);
      const res = await getDistance({
        longitude: dropAddress.longitude,
        latitude: dropAddress.latitude,
        longitude1: pickupAddress.longitude,
        latitude1: pickupAddress.latitude,
      });

      if (!res.data.distance) {
        setLoader(false);
        return toastError(
          "We can't navigate to selected addresses, please select other addresses"
        );
      }

      totalDistance = Number(
        res.data.distance.text.replace(/[^\d\.]*/g, "")
      ).toFixed(0);
      console.log(totalDistance);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      toastError(error.data.message);
      console.log(error);
    }

    if (userType === "Special user") {
      let price;
      try {
        setLoader(true);
        price = await priceRangeAmount(userId, totalDistance, deliverType);

        setLoader(false);
      } catch (error) {
        setLoader(false);
        toastError(error.data.message);
      }
      if (price.data != 0) {
        setEstimatePrice(price.data);
      }

      setLoader(false);
      const data = await calculateAmount(
        deliverType,
        totalDistance,
        startTime,
        pickupDate
      );
      if (data.error) {
        setEstimatePrice(0);
        setDeliveryMessage("");
        return toastError(data.error);
      } else {
        if (price.data == 0) {
          setEstimatePrice(data.price);
        }
        console.log(data);
        setwillDelivered(data.willdelivered);
        setDeliveryDate(data.deliveryDate);
        return setDeliveryMessage(data.message);
      }
    }

    if (totalDistance <= 10) {
      setEstimatePrice(20);
    } else {
      totalDistance = totalDistance - 10;
      setEstimatePrice(totalDistance * 3 + 20);
    }
    const time = new Date();
    if (time.getHours() < 11) {
      setwillDelivered("before 5pm");
      setDeliveryDate(moment(pickupDate).format("DD-MM-YYYY"));
    } else {
      setwillDelivered("before 5pm");
      setDeliveryDate(moment(pickupDate).add(1, "day").format("DD-MM-YYYY"));
    }
  };

  useEffect(() => {
    if (userType && userType !== "Special user") {
      _userList();
    }

    if (selectedCompanyId) {
      _userList();
    }
  }, [userType, selectedCompanyId]);

  useEffect(() => {
    if (userId) _getAddresses();
  }, [userId]);

  useEffect(() => {
    if (userType) _getCompanies();
  }, [userType]);

  useEffect(() => {
    if (userType === "Special user") {
      if (dropAddress.title && pickupAddress.title && deliverType) {
        calculatePrice();
      }
    }
    if (userType === "User")
      if (dropAddress.title && pickupAddress.title) {
        calculatePrice();
      }
  }, [changePrice, startTime, dropAddress, pickupAddress, pickupDate]);

  const customAddressOption = (props) => {
    const { innerRef, innerProps, data } = props;
    return (
      <div ref={innerRef} {...innerProps}>
        <div>
          {!data.new ? (
            <div
              className="row p-3 mx-2 border-bottom cursor-pointer"
              onClick={() => {
                if (data.drop) {
                  setDropAddress(data);
                }
                if (!data.drop) {
                  setPickUpAddress(data);
                }
              }}
            >
              <div className="cursor-pointer">{data.title}</div>
            </div>
          ) : (
            <div
              className="d-flex p-3 mx-2 justify-content-between border-bottom cursor-pointer"
              onClick={() => {
                if (!userId) {
                  return toastError("Please select user");
                }
                if (data.drop) {
                  setAddressType("drop");
                  setModelState(openModel.ADD_ADDRESS);
                }
                if (!data.drop) {
                  setAddressType("pickup");
                  setModelState(openModel.ADD_ADDRESS);
                }
              }}
            >
              <b className="text-primary cursor-pointer">Add Address</b>
            </div>
          )}
          {/* {addresses.length}
          <div
            className="d-flex p-3 mx-2 justify-content-between border-bottom cursor-pointer"
            onClick={() => {
              if (data.drop) {
                setAddressType("drop");
                setModelState(openModel.ADD_ADDRESS);
              }
              if (!data.drop) {
                setAddressType("pickup");
                setModelState(openModel.ADD_ADDRESS);
              }
            }}
          >
            <b className="text-primary cursor-pointer">Add Address</b>
          </div> */}
        </div>
      </div>
    );
  };

  const customOption = (props) => {
    const { innerRef, innerProps, data } = props;

    return (
      <div ref={innerRef} {...innerProps}>
        <div>
          {!data.new ? (
            <div
              className="row p-3 mx-2 border-bottom cursor-pointer"
              onClick={() => {
                if (data.selectCompany) {
                  setSelectedCompanyId(data._id);
                }
                if (data.user) {
                  setUserId(data._id);
                }
              }}
            >
              <div className="cursor-pointer">{data.name}</div>
            </div>
          ) : (
            <div
              className="d-flex p-3 mx-2 justify-content-between border-bottom cursor-pointer"
              onClick={() => {
                history.push({ pathname: "/adduser", user: true });
              }}
            >
              <b className="text-primary cursor-pointer">Add User</b>
            </div>
          )}
        </div>
      </div>
    );
  };

  const customOptionCompany = (props) => {
    const { innerRef, innerProps, data } = props;

    return (
      <div ref={innerRef} {...innerProps}>
        <div>
          {!data.new ? (
            <div
              className="row p-3 mx-2 border-bottom cursor-pointer"
              onClick={() => {
                if (data.selectCompany) {
                  setSelectedCompanyId(data._id);
                }
                if (data.user) {
                  setUserId(data._id);
                }
              }}
            >
              <div className="cursor-pointer">{data.name}</div>
            </div>
          ) : (
            <div
              className="d-flex p-3 mx-2 justify-content-between border-bottom cursor-pointer"
              onClick={() => {
                history.push({ pathname: "/addCompany", comapny: true });
              }}
            >
              <b className="text-primary cursor-pointer">Add Company</b>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {loader ? loading : ""}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Create Order</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>

                <li className="breadcrumb-item active">Create Order</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header" style={{ background: "#F46C03" }}>
              <h6 className="my-2">Order Details</h6>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <form>
              <div className="card-body ">
                <div className="row">
                  <div className="col-sm-6">
                    {/* select */}
                    <div className="form-group">
                      <label>Select User Type</label>
                      <select
                        value={userType}
                        className="custom-select"
                        onChange={async (e) => {
                          setUserType(e.target.value);
                          setUsers([]);
                          setAddresses([]);
                          setUserId("");
                          setPickUpAddress({});
                          setDropAddress({});
                        }}
                      >
                        <option value="" disabled selected hidden>
                          select user type
                        </option>
                        <option value="User">User</option>
                        <option value="Special user">Special User</option>
                      </select>
                    </div>
                  </div>
                  {userType === "Special user" ? (
                    <div className="col-sm-6">
                      {/* select */}
                      <div className="form-group">
                        <label>Select Company</label>
                        <Select
                          styles={customStyles}
                          value={
                            companies?.find(
                              (x) => x._id === selectedCompanyId
                            ) ||
                            []?.map((x) => ({
                              ...x,
                              value: x._id,
                              label: x.title,
                            }))
                          }
                          options={[
                            ...companies.map((company) => ({
                              ...company,
                              selectCompany: true,
                            })),
                            { new: true, drop: false },
                          ]}
                          components={{ Option: customOptionCompany }}
                          getOptionLabel={(option) => option.name}
                          // getOptionValue={(option) => option._id}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-sm-6">
                    {/* select */}
                    <div className="form-group">
                      <label>Select User</label>
                      <Select
                        styles={customStyles}
                        value={
                          users?.find((x) => x._id === userId) ||
                          []?.map((x) => ({
                            ...x,
                            value: x._id,
                            label: x.title,
                          }))
                        }
                        options={[
                          ...users.map((user) => ({
                            ...user,
                            user: true,
                          })),
                          { new: true, drop: false },
                        ]}
                        components={{ Option: customOption }}
                        getOptionLabel={(option) => option.name}
                        // getOptionValue={(option) => option._id}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Purchase Order Number
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="XYZ12345"
                        value={orderNumber}
                        onChange={(e) => {
                          setOrderNumber(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    {/* select */}
                    <div className="form-group">
                      <label>Pick Up Address</label>
                      <Select
                        // className="custom-select"
                        // classNamePrefix="name-select"
                        styles={customStyles}
                        // onChange={async (e) => {
                        // console.log(e);
                        //setDeliverType(e.value);
                        //setChangePrice(!changePrice);
                        // }}
                        value={
                          addresses?.find((x) => x._id === pickupAddress._id) ||
                          []?.map((x) => ({
                            ...x,
                            value: x._id,
                            label: x.title,
                          }))
                        }
                        options={[
                          ...addresses.map((address) => ({
                            ...address,
                            drop: false,
                          })),
                          { new: true, drop: false },
                        ]}
                        components={{ Option: customAddressOption }}
                        // isSearchable={true}
                        getOptionLabel={(option) => option.title}
                        // getOptionValue={(option) => option._id}
                      />

                     
                    </div>
                  </div>
                  <div className="col-sm-12">
                    {/* textarea */}
                    <div className="form-group">
                      <label>Pick Up Notes</label>
                      <textarea
                        className="form-control"
                        rows={3}
                        placeholder="Enter ..."
                        value={notes}
                        onChange={(e) => {
                          setNotes(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Drop Off address</label>
                      <Select
                        
                        styles={customStyles}
                        value={
                          addresses?.find((x) => x._id === dropAddress._id) ||
                          []?.map((x) => ({
                            ...x,
                            value: x._id,
                            label: x.title,
                          }))
                        }
                        options={[
                          ...addresses.map((address) => ({
                            ...address,
                            drop: true,
                          })),
                          { new: true, drop: true },
                        ]}
                        components={{ Option: customAddressOption }}
                        // isSearchable={true}
                        getOptionLabel={(option) => option.title}
                        // getOptionValue={(option) => option._id}
                      />
                     
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Phone Number
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="0123456789"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value.replace(/\D/g, ""));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Email</label>

                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="example `Microsoft`"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    {/* textarea */}
                    <div className="form-group">
                      <label>Drop notes</label>
                      <textarea
                        className="form-control"
                        rows={3}
                        placeholder="Enter ..."
                        value={dropNotes}
                        onChange={(e) => {
                          setDropNotes(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Pickup Date</label>

                      {/* <input
                        type="test"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="02/09/2021"
                      /> */}
                      <DatePicker
                        className="form-control"
                        selected={pickupDate}
                        onChange={(date) => setPickupDate(date)}
                        minDate={moment().toDate()}
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Pickup time</label>
                      <select
                        value={startTime}
                        className="custom-select"
                        onChange={async (e) => {
                          setStartTime(e.target.value);
                        }}
                      >
                        {PICKUP_TIME.map((time) => (
                          <option value={time}>{time}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {userType === "Special user" ? (
                    <div className="col-4">
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">
                          Delivery Type
                        </label>

                        <select
                          value={deliverType}
                          className="custom-select"
                          onChange={async (e) => {
                            setDeliverType(e.target.value);
                            setChangePrice(!changePrice);
                          }}
                        >
                          <option value="" disabled selected hidden>
                            select an option
                          </option>
                          <option value="Urgent">Urgent</option>
                          <option value="Any Time">Any Time</option>
                          {/* <option value="Any Future Day">Any Future Day</option> */}
                        </select>
                        <span>{deliveryMessage}</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {userType === "Special user" ? (
                    ""
                  ) : (
                    <div className="row">
                      <div className="col-2">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Quantity
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            value={quantity}
                            placeholder=""
                            onChange={(e) => {
                              if (e.target.value === "") {
                                setQuantity(e.target.value);
                                return;
                              }
                              if (e.target.value == 0) {
                                return;
                              }
                              setQuantity(e.target.value.replace(/\D/g, ""));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Length (cm)
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="cm"
                            value={length}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                if (e.target.value === "") {
                                  setLength(e.target.value);
                                  return;
                                }
                                if (e.target.value == 0) {
                                  return;
                                }
                                setLength(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            width (cm)
                          </label>

                          <input
                            type="test"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="cm"
                            value={width}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                if (e.target.value === "") {
                                  setWidth(e.target.value);
                                  return;
                                }
                                if (e.target.value == 0) {
                                  return;
                                }
                                setWidth(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            height (cm)
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="cm"
                            value={height}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                if (e.target.value === "") {
                                  setHeight(e.target.value);
                                  return;
                                }
                                if (e.target.value == 0) {
                                  return;
                                }
                                setHeight(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            weight (max weight 20kg)
                          </label>

                          <input
                            type="test"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="kg"
                            value={weight}
                            onChange={(e) => {
                              if (!isNaN(e.target.value)) {
                                if (e.target.value > 20) {
                                  return;
                                }
                                if (e.target.value === "") {
                                  setWeight(e.target.value);
                                  return;
                                }
                                if (e.target.value == 0) {
                                  return;
                                }
                                setWeight(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Package Description</label>
                          <textarea
                            className="form-control"
                            rows={3}
                            placeholder="Enter ..."
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <button
                            type="button"
                            style={{ background: "#F46C03", color: "white" }}
                            className="btn btn mr-3"
                            onClick={(e) => {
                              e.preventDefault();
                              _addItem();
                            }}
                          >
                            Add items
                          </button>
                        </div>
                      </div>
                      {packageItems.length ? (
                        <div className="col-12">
                          <div className="card">
                            {/* /.card-header */}
                            <div className="card-body table-responsive p-0">
                              <table className="table table-hover text-nowrap">
                                <thead>
                                  <tr>
                                    <th>Quantity</th>
                                    <th>Length (cm)</th>
                                    <th>Width (cm)</th>
                                    <th>Height (cm)</th>
                                    <th>Weight (kg)</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {packageItems.map((item) => (
                                    <tr key={item.id}>
                                      <td>{item.quantity}</td>
                                      <td>{item.length}</td>
                                      <td>{item.width}</td>
                                      <td>{item.height}</td>
                                      <td>{item.weight}</td>
                                      <td>{item.description}</td>
                                      <td>
                                        <i
                                          className="nav-icon fas "
                                          type="button"
                                          title="Remove item"
                                          onClick={() => {
                                            const array = packageItems.filter(
                                              (data) => {
                                                return data.id != item.id;
                                              }
                                            );
                                            setPackageItems(array);
                                          }}
                                        >
                                          <AiOutlineDelete />
                                        </i>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            {/* /.card-body */}
                          </div>
                          {/* /.card */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  <div className="row">
                    <div className="col-4">
                      <div
                        style={{
                          display: "grid",
                          gridAutoFlow: "column",
                          alignSelf: "center",
                        }}
                      >
                        <label style={{ marginTop: ".8vh" }}>
                          Estimate Price : &nbsp;
                        </label>
                        <input
                          value={estimatePrice}
                          type="email"
                          className="form-control"
                          // placeholder="0"

                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              if (e.target.value === "") {
                                setEstimatePrice(e.target.value);
                                return;
                              }
                              if (e.target.value == 0) {
                                return;
                              }
                              setEstimatePrice(e.target.value);
                            }
                            // setEstimatePrice(e.target.value.replace(/\D/g, ""))
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <label htmlFor="exampleInputPassword1">
                    Estimate Price : ${estimatePrice}
                  </label> */}
                </div>
              </div>
              {/* /.card-body */}
              <div className="card-footer">
                <button
                  type="button"
                  style={{ background: "#F46C03", color: "white" }}
                  className="btn btn mr-3"
                  onClick={() => {
                    _createOrder();
                  }}
                >
                  Confirm order
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <AddAddress
        userId={userId}
        addressType={addressType}
        setPickupAddress={(data) => setPickUpAddress(data)}
        setDropAddress={(data) => setDropAddress(data)}
        pushAddress={(data) => {
          setAddresses([...addresses, data]);
        }}
      />
    </div>
  );
}
