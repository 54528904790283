import React, { Suspense, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import { createBrowserHistory } from "history";
import Layout from "./components/Layout";
import { loading } from "./utlis/common";
import { ModalContext } from "./context/modalState";
import "./css/app.css";
import { DriverContext } from "./context/driverState";
import SpeechToText from "./SpeechToText";

const history = createBrowserHistory();

function App() {
  let auth = localStorage.accessToken;

  const [modelState, setModelState] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const changeDriverState = (data) => {
    setSelectedDriver(data);
  };
  const changeModelState = (data) => {
    setModelState(data);
  };
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <DriverContext.Provider
          value={{ selectedDriver, setSelectedDriver: changeDriverState }}
        >
          <ModalContext.Provider
            value={{ modelState, setModelState: changeModelState }}
          >
            <Switch>
              <Switch>
                <Route exact path="/speechToText" component={SpeechToText} />
                {auth && (
                  <Route
                    path="/"
                    name="Home"
                    render={(props) => <Layout {...props} />}
                  />
                )}
                {!auth && (
                  <Route
                    exact
                    path="/login"
                    name="Login Page"
                    render={(props) => <Login {...props} />}
                  />
                )}
                {!auth && (
                  <Route
                    render={() => {
                      history.push({ pathname: "/login" });
                      history.go("/login");
                    }}
                  />
                )}
              </Switch>
            </Switch>
          </ModalContext.Provider>
        </DriverContext.Provider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
