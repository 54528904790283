import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import SweetAlert from "react-bootstrap-sweetalert";

import { useModal } from "../../context/modalState";
import { driverList } from "../../networkRequests/driver";
import { userPerPage } from "../../utlis/appConstants";
import { openModel } from "../../utlis/appConstants";
import { assignJob } from "../../networkRequests/package";
import { loading, toastSuccess } from "../../utlis/common";
import { useDriver } from "../../context/driverState";
import { FiMapPin } from "react-icons/fi";

export default function AssignJob(props) {
  const history = useHistory();
  const { modelState, setModelState } = useModal();
  const { selectedDriver, setSelectedDriver } = useDriver();

  const [loader, setLoader] = useState(false);
  const [pageNumber, setpageNumber] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [pageChange, setPageChange] = useState(false);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("ASC");
  const [selected, setSelected] = useState("orderNumber");
  const [alertStatus, setAlertStatus] = useState(false);
  const [driver, setDriver] = useState();
  // const [packageId, setPackeageId] = useState();

  const pageCount = Math.ceil(userCount / userPerPage);

  const _driverList = async () => {
    try {
      setLoader(true);
      const res = await driverList(userPerPage, pageNumber, search);
      console.log(res);
      setUsers(res.data.user);
      setUserCount(res.data.count);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const _assignJob = async () => {
    try {
      setLoader(true);
      const res = await assignJob(selectedDriver, driver);
      setSelectedDriver(null);
      setModelState(openModel.NON);
      setLoader(false);
      toastSuccess("Job assigned successfully");
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const sorting = (col) => {
    if (order === "ASC" || order === null) {
      const sorted = [...users].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setUsers(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...users].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setUsers(sorted);
      setOrder("ASC");
    }
  };
  const sortNumber = (col) => {
    if (order === "ASC" || order === null) {
      const sorted = [...users].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setUsers(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...users].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setUsers(sorted);
      setOrder("ASC");
    }
  };
  useEffect(() => {
    setpageNumber(0);
    _driverList();
  }, [pageChange, modelState]);
  return (
    <div>
      {loader ? loading : ""}
      <Modal
        //styles={{ marginTop: "80px" }}
        open={modelState == openModel.ASSIGN_DRIVER ? true : false}
        onClose={() => {
          setModelState(openModel.NON);
        }}
      >
        <div>
          <div>
            <div className="row">
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex" }}>
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => {
                        history.push("/driverLoc");
                      }}
                    >
                      <FiMapPin className="me-3" style={{ marginTop: -3 }} />
                      View Map
                    </button>

                    <div
                      className="input-group input-group ml-2"
                      style={{ width: 150 }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          setpageNumber(0);
                          setSearch(e.target.value);
                          setPageChange(!pageChange);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="card-body table-responsive p-0 mt-4"
            style={{ height: "490px" }}
          >
            <table className="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th
                    onClick={() => {
                      sorting("name");
                      setSelected("name");
                    }}
                  >
                    Name&nbsp;
                    <img
                      src={
                        selected == "name"
                          ? order == null
                            ? ""
                            : order == "ASC"
                            ? "/dist/img/up-arrow.png"
                            : "/dist/img/down-arrow.png"
                          : ""
                      }
                    />
                  </th>
                  <th
                    onClick={() => {
                      sortNumber("email");
                      setSelected("email");
                    }}
                  >
                    Email
                    <img
                      src={
                        selected == "email"
                          ? order == null
                            ? ""
                            : order == "ASC"
                            ? "/dist/img/up-arrow.png"
                            : "/dist/img/down-arrow.png"
                          : ""
                      }
                    />
                  </th>

                  <th>Assign Job</th>
                </tr>
              </thead>
              <tbody>
                {users.map((m) => (
                  <tr key={m._id}>
                    <td>{m.name}</td>
                    <td>{m.email}</td>
                    <td>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm "
                          onClick={() => {
                            console.log("tttttttttttttttttttt");
                            setAlertStatus(true);
                            setDriver(m._id);
                          }}
                        >
                          Assign
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {userCount >= 10 ? (
            <div className="card-footer clearfix">
              <ReactPaginate
                previousLabel={<span aria-hidden="true">&laquo;</span>}
                nextLabel={<span aria-hidden="true">&raquo;</span>}
                breakLabel={"....."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                onPageChange={(selected) => {
                  //  console.log(selected.selected);
                  setpageNumber(selected.selected);
                  setPageChange(!pageChange);
                }}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"page-item active"}
                forcePage={pageNumber}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal>
      <SweetAlert
        show={alertStatus}
        warning
        showCancel
        confirmBtnText="Yes, assign"
        confirmBtnBsStyle="primary"
        title="Are you sure?"
        onConfirm={() => {
          setAlertStatus(false);
          _assignJob();
        }}
        onCancel={() => {
          setAlertStatus(false);
        }}
        //focusCancelBtn
      ></SweetAlert>
    </div>
  );
}
