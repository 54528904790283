import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Geocode from "react-geocode";
import { useHistory } from "react-router-dom";

import { useModal } from "../../context/modalState";
import { openModel } from "../../utlis/appConstants";
import { useDriver } from "../../context/driverState";
import { toastError } from "../../utlis/common";
import { assignJob } from "../../networkRequests/package";
import { toastSuccess, loading } from "../../utlis/common";

export default function DriverDetail(props) {
  const history = useHistory();
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
  const { modelState, setModelState } = useModal();
  const { selectedDriver, setSelectedDriver } = useDriver();
  const [loader, setLoader] = useState(false);
  const [city, setCity] = useState();

  const getCityName = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setCity(address);

        return address;
      },
      (error) => {
        console.error(error);
      }
    );
  };
  useEffect(() => {
    getCityName(props?.data?.lat, props?.data?.lng);
  }, [modelState]);
  return (
    <Modal
      open={modelState == openModel.DRIVER_LOC ? true : false}
      onClose={() => {
        setModelState(openModel.NON);
      }}
    >
      {loader ? loading : ""}
      <div className="card-body box-profile">
        <h3 className="profile-username text-center">{props?.data?.name}</h3>
        <p className="text-muted text-center">{city}</p>
        <ul className="list-group list-group-unbordered mb-3"></ul>
        <button
          // to="/package/new"
          className="btn btn-primary btn-block"
          onClick={async () => {
            if (!selectedDriver) {
              history.push("/package/new");
              setModelState(openModel.NON);
              return toastError("Please select a job first");
            }

            try {
              setLoader(true);
              
              const res = await assignJob(selectedDriver, props?.data?._id);
             
              setLoader(false);
            } catch (error) {
              console.log(error);
              setLoader(false);
            }
            setSelectedDriver(null);
            setModelState(openModel.NON);
            toastSuccess("Job successfully assigned");
            history.push("/package/new");
          }}
        >
          <b>{"Assign Job"}</b>
        </button>
      </div>
    </Modal>
  );
}
