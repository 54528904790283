import qs from "qs";

import {
  makePostRequest,
  makeGetRequest,
  makePutRequest,
  makeDeleteRequest,
} from "./axiosBase";

export const getPriceRanges = async () => {
  try {
    const params = {};
    let res = await makeGetRequest("/admin/priceRange/list", params);

    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getPriceRange = async (priceRangeId) => {
  try {
    const params = { priceRangeId };
    let res = await makeGetRequest("/admin/priceRange", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deletePriceRange = async (priceRangeId) => {
  try {
    const params = { priceRangeId };
    let res = await makeDeleteRequest("/admin/priceRange", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addPriceRangre = async (data) => {
  try {
    const config = { skipAuth: false };

    let res = await makePostRequest(
      "/admin/priceRange",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const editPriceRange = async (data) => {
  console.log(data);
  try {
    const config = { skipAuth: false };

    let res = await makePutRequest(
      "/admin/priceRange",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const changePriceRange = async (userId, priceRangeId) => {
  try {
    const data = {
      userId,
      priceRangeId,
    };
    const config = { skipAuth: false };

    let res = await makePostRequest(
      "/admin/priceRange/add",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const priceRangeAmount = async (userId, distance, type) => {
  try {
    
    const params = { userId, distance, type };
    let res = await makeGetRequest("/admin/priceRange/price", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
