import moment from "moment";

export const calculateAmount = async (
  deliveryType,
  totalDistance,
  pickuptime,
  pickupDate
) => {
  const data = {
    message: "",
    price: "",
    willdelivered: "before 5pm",
    deliveryDate: moment(pickupDate).add(1, "day").format("DD-MM-YYYY"),
  };
  const time = new Date();
  if (deliveryType === "Any Time") {
    if (
      time.getHours() < 11 ||
      moment(pickuptime, "hh:mm A").format("h") < 11
    ) {
      data.message = `Package will be delivered by 3:00 pm ${moment(
        pickupDate
      ).format("MM-DD-YYYY")}`;
      data.willdelivered = "3:00 pm";
      data.deliveryDate = moment(pickupDate).format("DD-MM-YYYY");
    } else {
      data.message = `Package will be delivered by 5:00 pm ${moment(pickupDate)
        .add(1, "day")
        .format("MM-DD-YYYY")}`;
      data.willdelivered = "5:00 pm";
      data.deliveryDate = moment(pickupDate).add(1, "day").format("DD-MM-YYYY");
    }

    if (totalDistance <= 20) {
      data.price = 50;
    } else if (totalDistance > 20 && totalDistance <= 35) {
      data.price = 90;
    } else if (totalDistance > 35 && totalDistance <= 50) {
      data.price = 125;
    } else if (totalDistance > 50 && totalDistance <= 70) {
      data.price = 175;
    }
    // else {
    //   return { error: "Sorry, we can't do delivery for distance above 70 km." };
    // }
  } else if (deliveryType === "Urgent") {
    if (
      time.getHours() < 13 &&
      moment(pickupDate).format("DD-MM-YYYY") == moment().format("DD-MM-YYYY")
    ) {
      data.message = `Package will be delivered ${moment(pickuptime, ["HH:mm "])
        .add(2, "hours")
        .format("hh:mm a")}.`;
      data.willdelivered = `after ${moment(pickuptime, ["HH:mm "])
        .add(2, "hours")
        .format("hh:mm a")}`;
      data.deliveryDate = moment(pickupDate).format("DD-MM-YYYY");
    } else if (
      (time.getHours() >= 13,
      moment(pickupDate).format("DD-MM-YYYY") == moment().format("DD-MM-YYYY"))
    ) {
      data.message = `Package will be delivered before 9:00 am ${moment(
        pickupDate
      )
        .add(1, "day")
        .format("DD-MM-YYYY")}`;
      data.willdelivered = `before 9:00 am`;
      data.deliveryDate = moment(pickupDate).add(1, "day").format("DD-MM-YYYY");
    } else {
      data.message = `Package will be delivered by ${moment(pickuptime, [
        "HH:mm ",
      ])
        .add(2, "hours")
        .format("hh:mm a")} ${moment(pickupDate).format("DD-MM-YYYY")}`;
      data.willdelivered = `after ${moment(pickuptime, ["HH:mm "])
        .add(2, "hours")
        .format("hh:mm a")}`;
      data.deliveryDate = moment(pickupDate).format("DD-MM-YYYY");
    }
    if (totalDistance <= 20) {
      data.price = 90;
    } else if (totalDistance > 20 && totalDistance <= 35) {
      data.price = 120;
    }
    // else {
    //   return {
    //     error: "Sorry, we can't do urgent delivery for distance above 35 km.",
    //   };
    // }
    // } else if (deliveryType === "Any Future Day") {
    //   data.message = "Package will be delivered soon.";
    //   data.willdelivered = `before 5:00 pm`;
    //   data.deliveryDate = moment(pickupDate).add(1, "day").format("DD-MM-YYYY");

    //   if (totalDistance <= 20) {
    //     data.price = 50;
    //   } else if (totalDistance > 20 && totalDistance <= 35) {
    //     data.price = 90;
    //   } else if (totalDistance > 35 && totalDistance <= 50) {
    //     data.price = 125;
    //   } else if (totalDistance > 50 && totalDistance <= 70) {
    //     data.price = 175;
    //   }
    //   // else {
    //   //   return { error: "Sorry, we can't do delivery for distance above 70 km." };
    //   // }
  }
  console.log(
    "kkkkkkkkkkkkkkkkkkkkk",
    moment(pickuptime, "hh:mm A").format("h")
  );
  data.price = data.price + (10 * data.price) / 100;

  return data;
};
