import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  toastError,
  toastSuccess,
  loading,
  deliveryStatus,
} from "../../utlis/common";
import { getPackages, deletePackage } from "../../networkRequests/package";
import { userPerPage, openModel } from "../../utlis/appConstants";
import { Code } from "react-content-loader";
import { useDriver } from "../../context/driverState";
import { useModal } from "../../context/modalState";
import AssignJob from "../user/AssignJob";
import SweetAlert from "react-bootstrap-sweetalert";

export default function OnGoing() {
  const history = useHistory();
  const { modelState, setModelState } = useModal();
  const { selectedDriver, setSelectedDriver } = useDriver();
  const [search, setSearch] = useState("");
  const [pageNumber, setpageNumber] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [pageChange, setPageChange] = useState(false);
  const [packages, setPackages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [selected, setSelected] = useState("orderNumber");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [toggle, setToggle] = useState(false);
  const [packageId, setpackageId] = useState();
  const [alertStatus, setAlertStatus] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");
  const [userPerPageCount, setUserPerPageCount] = useState(25);

  const pageCount = Math.ceil(userCount / userPerPageCount);

  const _getPackages = async () => {
    try {
      setLoader(true);
      const res = await getPackages(
        userPerPageCount,
        pageNumber,
        search,
        "Going",
        startDate ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : "",
        endDate ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS") : ""
      );

      setUserCount(res.data.count);
      setPackages(res.data.packages);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  // const onStatusChange = async (id, status) => {
  //   try {
  //     setLoader(true);
  //     const res = await updateStatus(id, status);
  //     toastSuccess(res.message);
  //     _getPackages();
  //     setLoader(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoader(false);
  //   }
  // };

  const sorting = (col) => {
    if (order === "ASC" || order === null) {
      const sorted = [...packages].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setPackages(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...packages].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setPackages(sorted);
      setOrder("ASC");
    }
  };

  const sortNumber = (col) => {
    if (order === "ASC" || order === null) {
      const sorted = [...packages].sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setPackages(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...packages].sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setPackages(sorted);
      setOrder("ASC");
    }
  };

  const _deleteJob = async () => {
    try {
      setLoader(true);
      const res = await deletePackage(selectedJob);
      toastSuccess("Job successfully deleted");
      await _getPackages();
      console.log(res);
      setLoader(false);
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    _getPackages();
  }, [pageChange]);

  useEffect(() => {
    if (modelState == openModel.NON) _getPackages();
  }, [modelState]);

  useEffect(() => {
    if (startDate & endDate) {
      _getPackages();
    }
  }, [toggle]);
  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Active Jobs</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">Jobs</li>
                <li className="breadcrumb-item active">Active</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {loader ? (
        <Code />
      ) : (
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#tranparent" }}
                  >
                    <h3 className="card-title"></h3>
                    <div className="row">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="col-filters">
                            <div className="input-group input-group-sm">
                              <DatePicker
                                placeholderText="Start date"
                                className="form-control"
                                selected={startDate}
                                maxDate={endDate}
                                onChange={(date) => {
                                  setStartDate(date);
                                  setToggle(!toggle);
                                }}
                              />
                            </div>
                            &nbsp; &nbsp;
                            <div className="input-group input-group-sm">
                              <DatePicker
                                placeholderText="End date"
                                className="form-control"
                                selected={endDate}
                                minDate={startDate}
                                onChange={(date) => {
                                  setEndDate(date);
                                  setToggle(!toggle);
                                }}
                              />
                            </div>
                            &nbsp; &nbsp;
                            <div className="input-group input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search"
                                value={search}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    setpageNumber(0);
                                    setPageChange(!pageChange);
                                  }
                                }}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card-body table-responsive p-0"
                    style={{ height: "480px" }}
                  >
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th
                            onClick={() => {
                              sorting("orderNumber");
                              setSelected("orderNumber");
                            }}
                          >
                            Order Number&nbsp;
                            <img
                              src={
                                selected == "orderNumber"
                                  ? order == null
                                    ? ""
                                    : order == "ASC"
                                    ? "/dist/img/up-arrow.png"
                                    : "/dist/img/down-arrow.png"
                                  : ""
                              }
                            />
                          </th>
                          <th
                            onClick={() => {
                              sortNumber("quantity");
                              setSelected("quantity");
                            }}
                          >
                            Customer Name
                            <img
                              src={
                                selected == "quantity"
                                  ? order == null
                                    ? ""
                                    : order == "ASC"
                                    ? "/dist/img/up-arrow.png"
                                    : "/dist/img/down-arrow.png"
                                  : ""
                              }
                            />
                          </th>
                          <th
                            onClick={() => {
                              sortNumber("price");
                              setSelected("price");
                            }}
                          >
                            Amount{" "}
                            <img
                              src={
                                selected == "price"
                                  ? order == null
                                    ? ""
                                    : order == "ASC"
                                    ? "/dist/img/up-arrow.png"
                                    : "/dist/img/down-arrow.png"
                                  : ""
                              }
                            />
                          </th>
                          <th
                            onClick={() => {
                              sorting("createdAt");
                              setSelected("createdAt");
                            }}
                          >
                            Order Placed On
                            <img
                              src={
                                selected == "createdAt"
                                  ? order == null
                                    ? ""
                                    : order == "ASC"
                                    ? "/dist/img/up-arrow.png"
                                    : "/dist/img/down-arrow.png"
                                  : ""
                              }
                            />
                          </th>

                          <th
                            onClick={() => {
                              sorting("commencedDropoffAt");
                              setSelected("commencedDropoffAt");
                            }}
                          >
                            Commenced pickup At
                            <img
                              src={
                                selected == "commencedDropoffAt"
                                  ? order == null
                                    ? ""
                                    : order == "ASC"
                                    ? "/dist/img/up-arrow.png"
                                    : "/dist/img/down-arrow.png"
                                  : ""
                              }
                            />
                          </th>
                          <th
                            onClick={() => {
                              sorting("commencedDropoffAt");
                              setSelected("commencedDropoffAt");
                            }}
                          >
                            Pickup At
                            <img
                              src={
                                selected == "commencedDropoffAt"
                                  ? order == null
                                    ? ""
                                    : order == "ASC"
                                    ? "/dist/img/up-arrow.png"
                                    : "/dist/img/down-arrow.png"
                                  : ""
                              }
                            />
                          </th>
                          <th
                            onClick={() => {
                              sorting("commencedDropoffAt");
                              setSelected("commencedDropoffAt");
                            }}
                          >
                            Commenced Dropoff At
                            <img
                              src={
                                selected == "commencedDropoffAt"
                                  ? order == null
                                    ? ""
                                    : order == "ASC"
                                    ? "/dist/img/up-arrow.png"
                                    : "/dist/img/down-arrow.png"
                                  : ""
                              }
                            />
                          </th>
                          <th
                            onClick={() => {
                              sorting("driver");
                              setSelected("driver");
                            }}
                          >
                            driver
                            <img
                              src={
                                selected == "driver"
                                  ? order == null
                                    ? ""
                                    : order == "ASC"
                                    ? "/dist/img/up-arrow.png"
                                    : "/dist/img/down-arrow.png"
                                  : ""
                              }
                            />
                          </th>
                          <th
                            onClick={() => {
                              sorting("status");
                              setSelected("status");
                            }}
                          >
                            Status
                            <img
                              src={
                                selected == "status"
                                  ? order == null
                                    ? ""
                                    : order == "ASC"
                                    ? "/dist/img/up-arrow.png"
                                    : "/dist/img/down-arrow.png"
                                  : ""
                              }
                            />
                          </th>
                          
                         
                           
                         
                         
                        
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {packages.map((m) => (
                          <tr key={m._id}>
                            <td>{m.orderNumber}</td>
                            <td>{`${m?.user?.name} ${
                              m.user.lastName ? m.user.lastName : ""
                            }`}</td>
                            <td>${m.price}</td>
                            <td>{moment(m.createdAt).format("LL")}</td>
                            <td>
                              {m.deliveryStartDt
                                ? moment(m.deliveryStartDt).format("lll")
                                : ""}
                            </td>
                            <td>
                              {m.pickedUpAt
                                ? moment(m.pickedUpAt).format("lll")
                                : ""}
                            </td>
                            <td>
                              {m.commencedDropoffAt
                                ? moment(m.commencedDropoffAt).format("lll")
                                : ""}
                            </td>
                            <td>{m.driver?.name}</td>

                            <td>
                              {m.status === deliveryStatus.ASSIGNED ? (
                                <div
                                  style={{
                                    color: "green",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedDriver(m._id);
                                    setModelState(openModel.ASSIGN_DRIVER);
                                    setpackageId(m._id);
                                  }}
                                >
                                  {m.status}
                                </div>
                              ) : (
                                <div className="badge badge-secondary">
                                  {m.status}
                                </div>
                              )}

                              {/* <div className="btn-group">
                       <button
                         
                         type="button"
                         className="btn btn-default btn-sm bg-danger"
                       >
                        
                       </button>
                     </div> */}
                            </td>
                            <td>
                              <i
                                className="nav-icon fas "
                                type="button"
                                title="View"
                                onClick={() => {
                                  history.push(`/package/${m._id}`);
                                }}
                              >
                                <AiOutlineEye />
                              </i>
                              &nbsp;
                              <i
                                className="nav-icon fas "
                                type="button"
                                title="Edit "
                                onClick={() => {
                                  history.push(`/package/update/${m._id}`);
                                }}
                              >
                                <BiEditAlt />
                              </i>
                              &nbsp;
                              <i
                                className="nav-icon fas "
                                type="button"
                                title="Delete Job"
                                onClick={() => {
                                  setAlertStatus(true);
                                  setSelectedJob(m._id);
                                  //history.push(`/package/update/${m._id}`);
                                }}
                              >
                                <AiOutlineDelete />
                              </i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {userCount >= 10 ? (
                    <div className="pagination-limit">
                      <ReactPaginate
                        previousLabel={<span aria-hidden="true">&laquo;</span>}
                        nextLabel={<span aria-hidden="true">&raquo;</span>}
                        breakLabel={"....."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        onPageChange={(selected) => {
                          //  console.log(selected.selected);
                          setpageNumber(selected.selected);
                          setPageChange(!pageChange);
                        }}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"page-item active"}
                        forcePage={pageNumber}
                      />
                      <select
                        value={userPerPageCount}
                        style={{ width: "160px", minWidth: "100px" }}
                        className="custom-select"
                        onChange={(e) => {
                          setpageNumber(0);
                          setUserPerPageCount(e.target.value);
                          setPageChange(!pageChange);
                        }}
                      >
                        <option value="" disabled selected hidden>
                          Limit
                        </option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="100000">All</option>
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <AssignJob packageId={packageId} />
      <SweetAlert
        show={alertStatus}
        warning
        showCancel
        confirmBtnText="Yes, delete"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          _deleteJob();
          setAlertStatus(false);
        }}
        onCancel={() => {
          setAlertStatus(false);
          // setSelectedCompanyId("");
        }}
        //focusCancelBtn
      />
    </div>
  );
}
