import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Select from "react-select";

import { addUser, createSpecialUser } from "../../networkRequests/user";
import {
  toastError,
  toastSuccess,
  loading,
  customStyles,
} from "../../utlis/common";
import { getCompanies } from "../../networkRequests/comapny";
import { getPriceRanges } from "../../networkRequests/priceRange";

export default function AddUser(props) {
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [loader, setLoader] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [priceRanges, setPriceRanges] = useState([]);
  const [priceRangeId, setPriceRangeId] = useState("");
  const [createUser, setCreateUser] = useState(false);

  //console.log(priceRanges);
  const _addUser = async (e) => {
    e.preventDefault();
    if (!name || !email) {
      return toastError("Please fill all fields");
    }
    if (!companyId) {
      return toastError("Please select company");
    }
    if (!priceRangeId) {
      return toastError("Please select price range");
    }
    try {
      setLoader(true);
      let res;
      if (createUser) {
        res = await createSpecialUser(name, email, companyId, priceRangeId);
      } else {
        res = await addUser(name, email, companyId, priceRangeId);
      }
      toastSuccess(res.message);
      setName("");
      setEmail("");
      setCompanyId("");
      setPriceRangeId("");
      document.getElementById("submit").reset();
      setLoader(false);
      if (props.location.user) {
        history.push("/job");
      }
    } catch (error) {
      toastError(error.data.message);
      setLoader(false);
    }
  };

  const _getCompanies = async () => {
    try {
      setLoader(true);
      const res = await getCompanies(0, 100);
      setCompanies(res.data.companies);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toastError(error.data.message);
      console.log(error);
    }
  };

  const _getPriceRanges = async () => {
    try {
      setLoader(true);
      const res = await getPriceRanges();
      setPriceRanges(res.data);
      //console.log(res.data[0]);
      setLoader(false);
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    _getCompanies();
    _getPriceRanges();
  }, []);

  const customOption = (props) => {
    const { innerRef, innerProps, data } = props;

    return (
      <div ref={innerRef} {...innerProps}>
        <div>
          {!data.new ? (
            <div
              className="row p-3 mx-2 border-bottom cursor-pointer"
              onClick={() => {
                if (data.company) {
                  setCompanyId(data._id);
                }
                if (data.priceRange) {
                  setPriceRangeId(data._id);
                }

                // if (data.selectCompany) {
                //   setSelectedCompanyId(data._id);
                // }
                // if (data.user) {
                //   setUserId(data._id);
                // }
              }}
            >
              <div className="cursor-pointer">{data.name}</div>
            </div>
          ) : (
            <div
              className="d-flex p-3 mx-2 justify-content-between border-bottom cursor-pointer"
              onClick={() => {
                history.push("/addCompany");
              }}
            >
              <b className="text-primary cursor-pointer">Add Company</b>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {loader ? loading : ""}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Invite User</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">
                  <NavLink to="/User">User</NavLink>
                </li>
                <li className="breadcrumb-item active">Add</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid row" style={{ marginLeft: 0 }}>
        <div className="card col-sm-6 px-0">
          <div className="card-header">
            <h6 className="my-2">Invite User</h6>
          </div>

          <form id="submit">
            <div className="card-body">
              <div className="form-group">
                <label>Name</label>
                <input
                  //type="password"
                  required
                  autofocus
                  className="form-control"
                  placeholder="Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  required
                  autofocus
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Select Company</label>

                <Select
                  styles={customStyles}
                  value={
                    companies?.find((x) => x._id === companyId) ||
                    []?.map((x) => ({
                      ...x,
                      value: x._id,
                      label: x.title,
                    }))
                  }
                  options={[
                    ...companies.map((company) => ({
                      ...company,
                      company: true,
                    })),
                    { new: true, drop: false },
                  ]}
                  components={{ Option: customOption }}
                  getOptionLabel={(option) => option.name}
                  // getOptionValue={(option) => option._id}
                />
              </div>
              <div className="form-group">
                <label>Select Price Range</label>

                <Select
                  styles={customStyles}
                  value={
                    priceRanges?.find((x) => x._id === priceRangeId) ||
                    []?.map((x) => ({
                      ...x,
                      value: x._id,
                      label: x.title,
                    }))
                  }
                  options={[
                    ...priceRanges.map((priceRange) => ({
                      ...priceRange,
                      priceRange: true,
                    })),
                  ]}
                  components={{ Option: customOption }}
                  getOptionLabel={(option) => option.name}
                  // getOptionValue={(option) => option._id}
                />
                {/* <select
                  value={companyId}
                  className="custom-select"
                  onChange={async (e) => {
                    setCompanyId(e.target.value);
                  }}
                >
                  <option value="" disabled selected hidden>
                    select a company
                  </option>
                  {companies.map((company) => (
                    <option value={company._id}>{company.name}</option>
                  ))}
                </select> */}
              </div>
              <div className="form-group">
                <input
                  //className="form-control"
                  type="checkbox"
                  name="remember"
                  id="remember"
                  defaultChecked={createUser}
                  onChange={() => {
                    setCreateUser(!createUser);
                  }}
                />
                &nbsp; &nbsp;
                <label> Create user without sending invite</label>
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer ">
              <button
                // type="submit"
                className="btn btn mr-3"
                onClick={_addUser}
                style={{ background: "#F46C03", color: "white" }}
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-secondary"
                onClick={() => {
                  setEmail("");
                  setName("");
                  setCompanyId("");
                }}
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
