import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FiPackage } from "react-icons/fi";
import { getPackageDetail } from "../../networkRequests/package";
import { loading } from "../../utlis/common";
import { awsUrl } from "../../utlis/appConstants";
import { openModel } from "../../utlis/appConstants";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import { useModal } from "../../context/modalState";

export default function Detail(props) {
  const { modelState, setModelState } = useModal();
  const [packageDetail, setPackageDetail] = useState({
    packageItems: [],
    images: [],
  });
  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const _getPackageDetail = async () => {
    try {
      setLoader(true);
      const res = await getPackageDetail(props.match.params.id);
      setPackageDetail(res.data.package);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  useEffect(() => {
    _getPackageDetail();
  }, []);

  return (
    <div>
      {loader ? loading : ""}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Package Detail</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">
                  <NavLink to="/package/new">Package</NavLink>
                </li>
                <li className="breadcrumb-item active">Detail</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* Main content */}
              <div className="invoice p-3 mb-3">
                {/* title row */}
                <div className="row">
                  <div className="col-12">
                    <h4>
                      <i className="nav-icon fas ">
                        <FiPackage />
                      </i>
                      Order Number #{packageDetail.orderNumber}
                      {/* <small className="float-right">Date: 2/10/2014</small> */}
                    </h4>
                  </div>
                  {/* /.col */}
                </div>
                {/* info row */}
                <div className="row invoice-info">
                  <div className="col-sm-4 invoice-col">
                    <strong>Pickup Detail</strong>
                    <address>
                      {/* {packageDetail.pickUpDetail
                        ? packageDetail.pickUpDetail.companyName
                        : ""}
                      <br /> */}
                      #
                      {packageDetail.pickUpDetail
                        ? packageDetail.pickUpDetail.address.fullAddress
                        : ""}
                      <br />
                      {packageDetail.pickUpDetail
                        ? packageDetail.pickUpDetail.notes
                        : ""}
                    </address>
                    <br />
                  </div>
                  {/* /.col */}
                  <div className="col-sm-4 invoice-col">
                    <strong>Drop Detail</strong>
                    <address>
                      {/* {packageDetail.dropDetail
                        ? packageDetail.dropDetail.name
                        : ""}
                      <br /> */}
                      #
                      {packageDetail.dropDetail
                        ? packageDetail.dropDetail.address.fullAddress
                        : ""}
                      <br />
                      {packageDetail?.dropDetail?.notes}
                    </address>
                    <b>Commenced pickup At: </b>
                    {packageDetail.deliveryStartDt
                      ? moment(packageDetail.deliveryStartDt).format("lll")
                      : ""}
                    <br />
                    <b>Pickup At: </b>
                    {packageDetail.pickedUpAt
                      ? moment(packageDetail.pickedUpAt).format("lll")
                      : ""}
                    <br />
                    <b> Commenced Dropoff At: </b>
                    {packageDetail.commencedDropoffAt
                      ? moment(packageDetail.commencedDropoffAt).format("lll")
                      : ""}
                    <br />
                  </div>
                  {/* /.col */}
                  <div className="col-sm-4 invoice-col">
                    <b>Estimate Price: </b>${packageDetail.price}
                    <br />
                    <b>Customer Name:</b>{" "}
                    {packageDetail.user ? packageDetail.user.name : ""}
                    <br />
                    <b>Phone Number:</b>{" "}
                    {packageDetail.dropDetail
                      ? packageDetail.dropDetail.phoneNumber
                      : ""}
                    <br />
                    <b>Email:</b>
                    {packageDetail.dropDetail
                      ? packageDetail.dropDetail.email
                      : ""}
                    <br />
                    <b>Company Name: </b>
                    {packageDetail?.user?.companyId?.name}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
                {/* Table row */}
                <div className="row">
                  <div className="col-12 table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>S No.</th>
                          <th>Dimensions</th>
                          <th>Weight(kg)</th>
                          <th>quantity</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {packageDetail.packageItems.map((items, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{`${items.length}x${items.width}x${items.height}`}</td>
                            <td>{items.weight} kg</td>
                            <td>{items.quantity}</td>
                            <td>{items.description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {packageDetail?.images.map((img) => (
                        <img
                          onClick={() => {
                            setModelState(openModel.VIEW_IMAGE);
                            setSelectedImage(`${awsUrl}${img}`);
                          }}
                          src={`${awsUrl}${img}`}
                          height={100}
                          width={100}
                          style={{ padding: 10, cursor: "pointer" }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={modelState == openModel.VIEW_IMAGE ? true : false}
        onClose={() => {
          setModelState(openModel.NULL);
        }}
      >
        <img
          src={selectedImage}
          // height={100}
          // width={100}
          style={{
            padding: 10,
            height: "600px",
            width: "600px",
            borderRadius: 15,
          }}
        />
      </Modal>
    </div>
  );
}
