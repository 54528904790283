import qs from "qs";

import {
  makePostRequest,
  makeGetRequest,
  makePutRequest,
  makeDeleteRequest,
} from "./axiosBase";


export const getPackages = async (
  limit,
  page,
  search,
  packageType,
  startDate,
  endDate,
  driverId,
  userId
) => {
  try {
    const params = {
      page,
      limit,
      search,
      packageType,
      startDate,
      endDate,
      driverId,
      userId,
    };
    let res = await makeGetRequest("/admin/package/list", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getPackageDetail = async (packageId) => {
  try {
    const params = {
      packageId,
    };
    let res = await makeGetRequest("/admin/package/detail", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateStatus = async (packageId, status) => {
  try {
    const data = {
      packageId,
      status,
    };
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/admin/package/updateStatus",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const assignJob = async (packageId, driver) => {
  try {
    const data = {
      packageId,
      driver,
    };
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/admin/package/assignJob",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getUsers = async (userType, companyId) => {
  try {
    const params = { userType, companyId };
    let res = await makeGetRequest("/admin/package/users", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getUserAddresses = async (userId) => {
  try {
    const params = { userId };
    let res = await makeGetRequest("/admin/package/user/addresses", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteAddresses = async (userId, addressId) => {
  try {
    const params = { userId, addressId };
    let res = await makeDeleteRequest("/admin/user/address", params);

    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createPackage = async (data) => {
  try {
   
    delete data.pickupAddress.isDefault;
    delete data.dropAddress.isDefault;
    delete data.pickupAddress._id;
    delete data.dropAddress._id;
    delete data.dropAddress.drop;
    delete data.pickupAddress.drop;
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/admin/package",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updatePackage = async (data) => {
  delete data.pickupAddress.isDefault;
  delete data.dropAddress.isDefault;
  delete data.pickupAddress._id;
  delete data.dropAddress._id;
  //delete data.dropAddress.addressId;
  //  delete data.pickupAddress.addressId;
  delete data.dropAddress.drop;
  delete data.pickupAddress.drop;
  try {
    const config = { skipAuth: false };
    let res = await makePutRequest(
      "/admin/package",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getUserAddress = async (userId, addressId) => {
  try {
    const params = { userId, addressId };
    let res = await makeGetRequest("/admin/package/user/address", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deletePackage = async (packageId) => {
  try {
    const params = { packageId };
    let res = await makeDeleteRequest("/admin/package", params);

    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
