import qs from "qs";
import {
  makePostRequest,
  makeGetRequest,
  makePutRequest,
  makeDeleteRequest,
} from "./axiosBase";

export const createCompany = async (
  name,
  businessAddress,
  accountEmail,
  abnNumber
) => {
  try {
    const data = {
      name,
      businessAddress,
      accountEmail,
      abnNumber,
    };
    const config = { skipAuth: false };

    let res = await makePostRequest(
      "/admin/company",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getCompanies = async (page, limit, search) => {
  try {
    const params = { page, limit, search };
    const config = { skipAuth: false };

    let res = await makeGetRequest("/admin/company", params, config, {
      crossDomain: true,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getCompany = async (companyId) => {
  try {
    const params = { companyId };
    const config = { skipAuth: false };

    let res = await makeGetRequest("/admin/company/single", params, config, {
      crossDomain: true,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCompany = async (companyId) => {
  try {
    const params = { companyId };
    const config = { skipAuth: false };

    let res = await makeDeleteRequest("/admin/company", params, config, {
      crossDomain: true,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const changeCompany = async (userId, companyId) => {
  try {
    const data = { userId, companyId };
    const config = { skipAuth: false };

    let res = await makePutRequest(
      "/admin/company/change",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const editCompany = async (
  companyId,
  name,
  businessAddress,
  accountEmail,
  abnNumber
) => {
  try {
    const data = { companyId, name, businessAddress, accountEmail, abnNumber };
    const config = { skipAuth: false };

    let res = await makePutRequest(
      "/admin/company",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};
