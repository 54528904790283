import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import GoogleMapReact from "google-map-react";
import { GiPositionMarker } from "react-icons/gi";
import Geocode from "react-geocode";

import { useModal } from "../../context/modalState";
import { openModel } from "../../utlis/appConstants";
import { toastError } from "../../utlis/common";
import { addAddress } from "../../networkRequests/user";

export default function AddAddress({
  pushAddress,
  userId,
  addressType,
  setPickupAddress,
  setDropAddress,
}) {
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
  const { modelState, setModelState } = useModal();
  const [address, setAddress] = useState("");
  const [fullAddress, setFullAddress] = useState();
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [title, setTitle] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const [zoom, setZoom] = useState(16);
  const [saveAddress, setSaveAddress] = useState(false);
  const [clickMarker, setClickMarker] = useState(false);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    setAddress(value);
    const coordinates = await getLatLng(results[0]);
    setLongitude(coordinates.lng);
    setLatitude(coordinates.lat);

   
  };

  const _addAddress = async () => {
    if (!title) {
      return toastError("Please enter title.");
    }
    if (!longitude) {
      return toastError("Please select address.");
    }
    if (!address) {
      return toastError("Please enter address");
    }

    if (saveAddress) {
      try {
        const res = await addAddress({
          userId,
          address,
          latitude,
          longitude,
          title,
          lotNumber,
          fullAddress,
        });
      } catch (error) {
        console.log(error);
        toastError(error.data.message);
      }
    }

    const _id = new Date().getTime();
    const data = {
      _id,
      address,
      fullAddress,
      latitude,
      longitude,
      title,
      lotNumber,
    };
    pushAddress(data);
    if (addressType === "pickup") {
      setPickupAddress(data);
    } else {
      setDropAddress(data);
    }
    setAddress("");
    setLongitude("");
    setLatitude("");
    setTitle("");
    setLotNumber("");
    setFullAddress("");
    setModelState(openModel.NON);

    // console.log(address, latitude, longitude, title, houseNo, zipCode);
  };

  const AnyReactComponent = ({ text }) => (
    <div>
      <img src="/dist/img/marker.webp"></img>
    </div>
  );

  const getCityName = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);

        return address;
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    if (modelState === openModel.ADD_ADDRESS) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.log(error);
          setLatitude(-23.6993336);
          setLongitude(133.8713537);
          // toastError(error.message);
          //setCenter({
          // lat: 25.2744,
          // lng: 133.7751,
          // });
        }
        // { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      );
    }
  }, [modelState]);
  useEffect(() => {
    if (!address) getCityName(latitude, longitude);
  }, [latitude, longitude]);
  useEffect(() => {
    getCityName(latitude, longitude);
  }, [clickMarker]);

  return (
    <Modal
      open={modelState == openModel.ADD_ADDRESS ? true : false}
      onClose={() => {
        setModelState(openModel.NON);
      }}
      
    >
      <div className="card-header" style={{ width: "75vh" }}>
        <h3 className="card-title">Add Address</h3>
      </div>
      <div style={{ height: "25vh", width: "100%" }}>
        {latitude ? (
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
            center={{ lat: latitude, lng: longitude }}
            defaultZoom={zoom}
            //draggable={true}
            // onChange={(center, zoom) => {
            //   console.log();
            // }}
            onClick={(data) => {
              setClickMarker(!clickMarker);
              setLatitude(data.lat);
              setLongitude(data.lng);
            }}
          >
            <AnyReactComponent lat={latitude} lng={longitude} />
          </GoogleMapReact>
        ) : (
          ""
        )}
      </div>

      <form>
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Title/Name</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Home"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Full Address</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Home"
              value={fullAddress}
              onChange={(e) => {
                setFullAddress(e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Address</label>
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: "search places ...",
                      className: "form-control",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={suggestion.placeId}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Lot Number</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              placeholder="123ABC"
              value={lotNumber}
              onChange={(e) => {
                setLotNumber(e.target.value);
              }}
            />
          </div>

          <div className="form-group row">
            <div className="col-md-6 ">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="remember"
                  id="remember"
                  defaultChecked={saveAddress}
                  onChange={() => {
                    setSaveAddress(!saveAddress);
                  }}
                />
                <label className="form-check-label" htmlFor="remember">
                  Save Address
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* /.card-body */}
        <div
          className="card-footer"
          onClick={(e) => {
            e.preventDefault();
            _addAddress();
          }}
        >
          <button
            type="submit"
            className="btn"
            style={{ background: "#F46C03", color: "white" }}
          >
            Add Address
          </button>
        </div>
      </form>
    </Modal>
  );
}
