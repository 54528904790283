import React from "react";
import { useHistory, NavLink } from "react-router-dom";

export default function Header() {
  let history = useHistory();
  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto  ">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="fullscreen"
              href="#"
              role="button"
            >
              <i className="fas fa-expand-arrows-alt" />
            </a>
          </li>
          <li style={{ marginRight: "10px" }} className="nav-item dropdown">
            <a
              id="dropdownSubMenu1"
              href="#"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              className="nav-link dropdown-toggle"
            >
              Admin
            </a>
            <ul
              aria-labelledby="dropdownSubMenu1"
              className="dropdown-menu  dropdown-menu-left "
              style={{ left: "inherit", right: 0 }}
            >
              <li>
                <NavLink to="/changePassword" className="dropdown-item">
                  Change password
                </NavLink>
              </li>
              <li>
                <a
                  href="#"
                  className="dropdown-item"
                  onClick={() => {
                    localStorage.removeItem("accessToken");
                    history.push({ pathname: "/login" });
                    history.go("/login");
                  }}
                >
                  Logout
                </a>
              </li>

              {/* <li className="dropdown-divider" /> */}
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
}
