import React, { useEffect, useState } from "react";
import { useModal } from "../../context/modalState";
import { openModel } from "../../utlis/appConstants";
import { Modal } from "react-responsive-modal";
import {
  getPriceRanges,
  changePriceRange,
} from "../../networkRequests/priceRange";
import { toastError, toastSuccess } from "../../utlis/common";

export default function ChangePriceRange({ user, onPress }) {
  const { modelState, setModelState } = useModal();

  const [priceRanges, setPriceRanges] = useState([]);

  const [loader, setLoader] = useState(false);

  const _getPriceRanges = async () => {
    try {
      setLoader(true);
      const res = await getPriceRanges();
      setPriceRanges(res.data);

      setLoader(false);
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  const _changePriceRange = async (priceRangeId) => {
    try {
      const res = await changePriceRange(user, priceRangeId);
      onPress();
      toastSuccess("Price range successfully updated");
      setModelState(openModel.NON);
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
    }
  };
  useEffect(() => {
    _getPriceRanges();
  }, []);

  return (
    <Modal
      open={modelState == openModel.CHANGE_PRICE_RANGE ? true : false}
      onClose={() => {
        setModelState(openModel.NON);
      }}
    >
      <div
        className="card-body table-responsive p-0 mt-4"
        style={{ height: "490px" }}
      >
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th>Name</th>
              <th>Urgent(1-20)</th>
              <th>Urgent(21-35)</th>
              <th>Standard(1-20)</th>
              <th>Standard(21-35)</th>
              <th>Standard(36-50)</th>
              <th>Standard(51-70)</th>
            </tr>
          </thead>
          <tbody>
            {priceRanges.map((m) => (
              <tr
                key={m._id}
                onClick={() => {
                  _changePriceRange(m._id);
                }}
              >
                <td>{m.name}</td>
                <td>$ {m.urgent ? m.urgent["1-20"] : ""}</td>
                <td>$ {m.urgent ? m.urgent["21-35"] : ""} </td>
                <td>$ {m.anyTime ? m.anyTime["1-20"] : ""}</td>
                <td>$ {m.anyTime ? m.anyTime["21-35"] : ""} </td>
                <td>$ {m.anyTime ? m.anyTime["36-50"] : ""} </td>
                <td>$ {m.anyTime ? m.anyTime["51-70"] : ""} </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
