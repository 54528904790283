import React, { Component } from "react";
import shouldPureComponentUpdate from "react-pure-render/function";

import { greatPlaceStyle } from "./my_great_place_styles.js";

export default class MyGreatPlace extends Component {
  static defaultProps = {};

  shouldComponentUpdate = shouldPureComponentUpdate;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {/* <button
          className="btn  mr-3"
          style={{ background: "#F46C03", color: "white" }}
        >
          Submit
        </button> */}
        <div
          onClick={() => {
            console.log("my grate place");
          }}
          style={greatPlaceStyle}
        >
          {this.props.text}
        </div>
      </div>
    );
  }
}
