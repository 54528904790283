import axios from "axios";
import { baseUrl } from "../utlis/appConstants";

export const handleError = (error) => {
  if (
    error.response.data.code == 401 &&
    error.response.data.message == "Please authenticate"
  ) {
    localStorage.removeItem("accessToken");
    window.location.reload();
  }
  throw error.response;
};

export const makePostRequest = (url, data = {}, config, options = {}) => {
  config = {
    skipAuth: false,
    contentType: "application/x-www-form-urlencoded",
    ...config,
  };
  const header = {
    headers: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      authorization: config.skipAuth
        ? null
        : "Bearer " + localStorage.getItem("accessToken"),
      "Content-Type": config.contentType,
    },
  };

  return axios
    .post(`${baseUrl}${url}`, data, header, options)
    .catch((error) => handleError(error));
};

export const makeGetRequest = (url, params = {}, config, options = {}) => {
  config = {
    skipAuth: false,
    contentType: "application/x-www-form-urlencoded",
    ...config,
  };

  const header = {
    params,
    headers: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      authorization: config.skipAuth
        ? null
        : "Bearer " + localStorage.getItem("accessToken"),
      "Content-Type": config.contentType,
    },
  };

  return axios
    .get(`${baseUrl}${url}`, header)
    .catch((error) => handleError(error));
};

export const makeDeleteRequest = (url, params = {}, config) => {
  config = {
    skipAuth: false,
    contentType: "application/x-www-form-urlencoded",
    ...config,
  };
  const reqConfig = {
    params,
    headers: {
      authorization: config.skipAuth
        ? null
        : "Bearer " + localStorage.getItem("accessToken"),
      "Content-Type": config.contentType,
    },
  };
  return axios
    .delete(`${baseUrl}${url}`, reqConfig)
    .catch((error) => handleError(error));
};

export const makePutRequest = (url, data = {}, config, options = {}) => {
  config = {
    skipAuth: false,
    contentType: "application/x-www-form-urlencoded",
    ...config,
  };
  const header = {
    headers: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      authorization: config.skipAuth
        ? null
        : "Bearer " + localStorage.getItem("accessToken"),
      "Content-Type": config.contentType,
    },
  };

  return axios
    .put(`${baseUrl}${url}`, data, header, options)
    .catch((error) => handleError(error));
};
