import qs from "qs";

import {
  makePostRequest,
  makeGetRequest,
  makePutRequest,
  makeDeleteRequest,
} from "./axiosBase";

export const driverList = async (limit, page, search) => {
  try {
    const params = {
      page,
      limit,
      search,
    };
    let res = await makeGetRequest("/admin/driver", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const onlineDrivers = async (limit, page, search) => {
  try {
    const params = {
      page,
      limit,
      search,
    };
    let res = await makeGetRequest("/admin/driver/online", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const blockDriver = async (driverId) => {
  try {
    const data = {
      driverId,
    };
    const config = { skipAuth: false };
    let res = await makePostRequest(
      "/admin/driver/block",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getDriverLoc = async (limit, page, search) => {
  try {
    let res = await makeGetRequest("/admin/driver/location");
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addDriver = async (data) => {
  try {
    const config = { skipAuth: false };

    let res = await makePostRequest(
      "/admin/driver",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getDriver = async (driverId) => {
  try {
    const params = {
      driverId,
    };
    let res = await makeGetRequest("/admin/driver/single", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editDriver = async (data) => {
  try {
    const config = { skipAuth: false };

    let res = await makePutRequest(
      "/admin/driver",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDriver = async (driverId) => {
  try {
    const config = { skipAuth: false };

    let res = await makeDeleteRequest("/admin/driver", { driverId }, config, {
      crossDomain: true,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
