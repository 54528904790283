import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import moment from "moment";
import { AiFillEye } from "react-icons/ai";
import { FaUserAlt, FaPhone, FaIndustry } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

import { userDetail } from "../../networkRequests/user";
import { userPerPage } from "../../utlis/appConstants";
import ReactPaginate from "react-paginate";

import { loading, deliveryStatus, toastSuccess } from "../../utlis/common";

export default function Detail(props) {
  const history = useHistory();

  const [user, setUser] = useState({});
  const [packages, setPackages] = useState([]);
  const [search, setSearch] = useState("");
  const [packagesCount, setPackagesCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [pageChange, setPageChange] = useState(false);
  const [pageNumber, setpageNumber] = useState(0);

  const pageCount = Math.ceil(packagesCount / userPerPage);

  const _userDetail = async () => {
    try {
      setLoader(true);
      const res = await userDetail(
        props.match.params.id,
        userPerPage,
        pageNumber,
        search
      );
      setUser(res.data.user);
      setPackages(res.data.packages);
      setPackagesCount(res.data.count);
      setLoader(false);
      console.log(res);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    _userDetail();
  }, [pageChange]);

  return (
    <div>
      {loader ? loading : ""}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">User Detail</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">
                  <NavLink to="/package/new">Package</NavLink>
                </li>
                <li className="breadcrumb-item active">Detail</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="invoice p-3 mb-3">
                <div className="row">
                  <div className="col-12">
                    <h6>
                      {/* User Name: {user.name} ({user.email}) */}
                      {/* <small className="float-right">Date: 2/10/2014</small> */}
                    </h6>
                  </div>
                  {/* /.col */}
                </div>

                <div className="row">
                  <div className="col-4">
                    <div className="card ">
                      {/* /.card-header */}
                      <div className="card-body">
                        <strong>
                          <i className="mr-1">
                            <FaUserAlt />
                          </i>{" "}
                          Name
                        </strong>
                        <p className="text-muted">{user.name}</p>
                        <hr />
                        <strong>
                          <i className="mr-1">
                            <MdEmail />
                          </i>
                          Email
                        </strong>
                        <p className="text-muted">{user.email}</p>
                        <hr />
                        <strong>
                          <i className=" mr-1">
                            <FaPhone />
                          </i>
                          Phone Number
                        </strong>
                        <p className="text-muted">{user.phoneNumber}</p>
                        <hr />
                        <strong>
                          <i className="mr-1">
                            <FaIndustry />
                          </i>
                          Company Name
                        </strong>
                        <p className="text-muted">{user.companyName}</p>

                        <hr />

                        <button
                          // type="submit"
                          className="btn mr-4 "
                          onClick={() => {
                            history.push(`/address/${props.match.params.id}`);
                          }}
                          style={{
                            background: "#F46C03",
                            color: "white",
                          }}
                        >
                          View Addresses
                        </button>

                        <button
                          // type="submit"
                          className="btn btn-secondary"
                          onClick={() => {
                            history.push(
                              `/user/deatail/${props.match.params.id}`
                            );
                          }}
                          // style={{
                          //   background: "#F46C03",
                          //   color: "white",
                          // }}
                        >
                          Edit Details
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-8">
                    <div className="card">
                      <div
                        className="card-header"
                        style={{ background: "#tranparent" }}
                      >
                        {/* <h3 className="card-title">
                          {" "}
                          User Name: {user.name} ({user.email})
                        </h3> */}
                        <div className="card-tools">
                          <div
                            className="input-group bg-white input-group-sm border"
                            style={{ borderRadius: 3 }}
                          >
                            <input
                              type="text"
                              name="table_search"
                              className="form-control float-right border-0"
                              placeholder="Search"
                              value={search}
                              onChange={(e) => {
                                setpageNumber(0);
                                setSearch(e.target.value);
                                setPageChange(!pageChange);
                              }}
                            />
                            <div className="input-group-append">
                              <button type="submit" className="btn btn-default">
                                <i className="fas fa-search" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="card-body table-responsive p-0"
                        style={{ height: "480px" }}
                      >
                        <table className="table table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th>Order Number</th>
                              <th>Quantity</th>
                              <th>Amount</th>
                              <th>Order Placed On</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {packages.map((m) => (
                              <tr key={m._id}>
                                <td>{m.orderNumber}</td>
                                <td>{m.packageItems.length}</td>
                                <td>${m.price}</td>
                                <td>{moment(m.createdAt).format("LL")}</td>
                                <td>
                                  <div className="badge badge-secondary">
                                    {m.status}
                                  </div>
                                </td>
                                <td>
                                  &nbsp; &nbsp;
                                  <i
                                    className="nav-icon fas "
                                    type="button"
                                    title="View"
                                    onClick={() => {
                                      history.push(`/package/${m._id}`);
                                    }}
                                  >
                                    <AiFillEye />
                                  </i>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {packagesCount >= 10 ? (
                        <div className="card-footer clearfix">
                          <ReactPaginate
                            previousLabel={
                              <span aria-hidden="true">&laquo;</span>
                            }
                            nextLabel={<span aria-hidden="true">&raquo;</span>}
                            breakLabel={"....."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            onPageChange={(selected) => {
                              //  console.log(selected.selected);
                              setpageNumber(selected.selected);
                              setPageChange(!pageChange);
                            }}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"page-item active"}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

{
  /* <div className="row invoice-info">
                  <div className="col-sm-4 invoice-col">
                    <strong>Pickup Detail</strong>
                    <address>
                      Company Name
                      <br /># {"San Francisco, CA 94107"}
                      <br />
                      Pickup note
                    </address>
                    <br />
                  </div>
                 
                  <div className="col-sm-4 invoice-col">
                    <strong>Drop Detail</strong>
                    <address>
                      Company Name
                      <br /># San Francisco, CA 94107 San Francisco, CA 94107
                    </address>
                  </div>
                 
                  <div className="col-sm-4 invoice-col">
                    <b>Estimate Price: </b>$12
                    <br />
                    <br />
                    <b>Customer Name:</b> Test customer
                    <br />
                    <b>Phone Number:</b> 1234789560
                    <br />
                    <b>Email:</b> test@gmail.com
                  </div>
                 
                </div> */
}
