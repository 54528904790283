import React from "react";

import New from "./pages/package/New";
import OnGoing from "./pages/package/OnGoing";
import AddUser from "./pages/user/InviteUser";
import SpecialUser from "./pages/user/SpecialUserList";
import PackageDetail from "./pages/package/Detail";
import UserDetail from "./pages/user/Detail";
import DriverLoc from "./pages/driver/DriverLoc";
import createJob from "./pages/package/CreateJob";
const History = React.lazy(() => import("./pages/package/History"));
const User = React.lazy(() => import("./pages/user/UserList"));
const Driver = React.lazy(() => import("./pages/driver/DriverList"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));
const AddDriver = React.lazy(() => import("./pages/driver/InviteDriver"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const addCompany = React.lazy(() => import("./pages/company/Add"));
const companyList = React.lazy(() => import("./pages/company/List"));
const editJob = React.lazy(() => import("./pages/package/EditJob"));
const editCompany = React.lazy(() => import("./pages/company/Edit"));
const editDriver = React.lazy(() => import("./pages/driver/EditDriver"));
const addressList = React.lazy(() => import("./pages/user/AddressList"));
const editUserDetails = React.lazy(() => import("./pages/user/EditDetails"));
const addPriceRange = React.lazy(() =>
  import("./pages/priceRange/AddPriceRange")
);
const getPriceRanges = React.lazy(() =>
  import("./pages/priceRange/PriceRangeList")
);

const editPriceRange = React.lazy(() =>
  import("./pages/priceRange/EditPriceRange")
);
const routes = [
  { path: "/home", exact: true, name: "", component: Dashboard },
  {
    path: "/package/new",
    exact: true,
    name: "New",
    component: New,
  },
  {
    path: "/package/onGoing",
    exact: true,
    name: "OnGoing",
    component: OnGoing,
  },
  {
    path: "/package/history",
    exact: true,
    name: "History",
    component: History,
  },
  {
    path: "/package/history",
    exact: true,
    name: "History",
    component: History,
  },
  {
    path: "/user",
    exact: true,
    name: "User",
    component: User,
  },
  {
    path: "/driver",
    exact: true,
    name: "Driver",
    component: Driver,
  },
  {
    path: "/changePassword",
    exact: true,
    name: "Change Password",
    component: ChangePassword,
  },
  {
    path: "/addDriver",
    exact: true,
    name: "Add Driver",
    component: AddDriver,
  },
  {
    path: "/adduser",
    exact: true,
    name: "Add Driver",
    component: AddUser,
  },
  {
    path: "/adduser",
    exact: true,
    name: "Add User",
    component: AddUser,
  },
  {
    path: "/specialUser",
    exact: true,
    name: "Special User",
    component: SpecialUser,
  },
  {
    path: "/package/:id",
    exact: true,
    name: "Package Detail",
    component: PackageDetail,
  },
  {
    path: "/user/:id",
    exact: true,
    name: "Package Detail",
    component: UserDetail,
  },
  {
    path: "/driverLoc",
    exact: true,
    name: "Driver Location",
    component: DriverLoc,
  },
  {
    path: "/job",
    exact: true,
    name: "Create Job",
    component: createJob,
  },
  {
    path: "/addCompany",
    exact: true,
    name: "Add Company",
    component: addCompany,
  },
  {
    path: "/companyList",
    exact: true,
    name: "Company List",
    component: companyList,
  },
  {
    path: "/company/update/:companyId",
    exact: true,
    name: "Company List",
    component: editCompany,
  },
  {
    path: "/package/update/:packageId",
    exact: true,
    name: "Package Update",
    component: editJob,
  },
  {
    path: "/driver/:driverId",
    exact: true,
    name: "Edit Driver",
    component: editDriver,
  },
  {
    path: "/address/:userId",
    exact: true,
    name: "Edit Driver",
    component: addressList,
  },
  {
    path: "/user/deatail/:userId",
    exact: true,
    name: "Edit User Details",
    component: editUserDetails,
  },
  {
    path: "/addPriceRange",
    exact: true,
    name: "Add Price Range",
    component: addPriceRange,
  },
  {
    path: "/priceRange",
    exact: true,
    name: "Add Price Range",
    component: getPriceRanges,
  },
  {
    path: "/priceRange/:priceRangeId",
    exact: true,
    name: "Edit Price Range",
    component: editPriceRange,
  },
];
export default routes;
