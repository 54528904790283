import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ReactPaginate from "react-paginate";
import { Code } from "react-content-loader";

import { useModal } from "../../context/modalState";
import { openModel } from "../../utlis/appConstants";
import { getCompanies, changeCompany } from "../../networkRequests/comapny";
import { userPerPage } from "../../utlis/appConstants";
import { toastError, toastSuccess } from "../../utlis/common";

export default function ChangeCompany({ user, onPress }) {
  const { modelState, setModelState } = useModal();
  const [pageNumber, setpageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [pageChange, setPageChange] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [selected, setSelected] = useState("name");

  const pageCount = Math.ceil(count / userPerPage);

  const _getCompanies = async () => {
    try {
      setLoader(true);
      const res = await getCompanies(pageNumber, userPerPage);

      setCompanies(res.data.companies);
      setCount(res.data.count);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const sorting = (col) => {
    if (order === "ASC" || order === null) {
      const sorted = [...companies].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setCompanies(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...companies].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setCompanies(sorted);
      setOrder("ASC");
    }
  };

  const _changeCompany = async (companyId) => {
    try {
      const res = await changeCompany(user, companyId);
      onPress();
      setModelState(openModel.NON);
      toastSuccess("Company successfully changed");
    } catch (error) {
      toastError(error.data.messae);
      console.log("mhjkfhdjkhkjghkhdfkghkfhdkg", error);
    }
    // console.log(props.user, companyId);
  };

  useEffect(() => {
    _getCompanies();
  }, [pageChange]);

  return (
    <Modal
      open={modelState == openModel.CHANGE_COMPANY ? true : false}
      onClose={() => {
        setModelState(openModel.NON);
      }}
    >
      <section>
        <div>
          <div className="row">
            <div className="col-12">
              <div>
                {/* <div
                    className="card-header"
                    style={{ background: "#tranparent" }}
                  >
                    <h3 className="card-title"></h3>
                    <div className="card-tools">
                      <div
                        className="input-group border bg-white input-group-sm"
                        style={{ borderRadius: 3 }}
                      >
                        <input
                          type="text"
                          name="table_search"
                          className="form-control float-right border-0"
                          placeholder="Search"
                          value={search}
                          onChange={(e) => {
                            setpageNumber(0);
                            setSearch(e.target.value);
                            setPageChange(!pageChange);
                          }}
                        />
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}

                <div
                  className="card-body table-responsive p-0 mt-4"
                  style={{ height: "490px" }}
                >
                  <table className="table table-hover text-nowrap">
                    <thead>
                      <tr>
                        <th
                          onClick={() => {
                            sorting("name");
                            setSelected("name");
                          }}
                        >
                          Name &nbsp;
                          <img
                            src={
                              selected == "name"
                                ? order == null
                                  ? ""
                                  : order == "ASC"
                                  ? "/dist/img/up-arrow.png"
                                  : "/dist/img/down-arrow.png"
                                : ""
                            }
                            //className="brand-image img-circle elevation-3"
                            style={{ opacity: ".8" }}
                          />
                        </th>
                        <th
                          onClick={() => {
                            sorting("accountEmail");
                            setSelected("email");
                          }}
                        >
                          Email &nbsp;
                          <img
                            src={
                              selected == "email"
                                ? order == null
                                  ? ""
                                  : order == "ASC"
                                  ? "/dist/img/up-arrow.png"
                                  : "/dist/img/down-arrow.png"
                                : ""
                            }
                          />
                        </th>
                        <th
                          onClick={() => {
                            sorting("businessAddress");
                            setSelected("address");
                          }}
                        >
                          Address
                          <img
                            src={
                              selected == "address"
                                ? order == null
                                  ? ""
                                  : order == "ASC"
                                  ? "/dist/img/up-arrow.png"
                                  : "/dist/img/down-arrow.png"
                                : ""
                            }
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {companies.map((m) => (
                        <tr
                          key={m._id}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            _changeCompany(m._id);
                          }}
                        >
                          <td>{m.name}</td>
                          <td>{m.accountEmail}</td>
                          <td>{m.businessAddress}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {count >= 10 ? (
                  <div className="card-footer clearfix">
                    <ReactPaginate
                      previousLabel={<span aria-hidden="true">&laquo;</span>}
                      nextLabel={<span aria-hidden="true">&raquo;</span>}
                      breakLabel={"....."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      onPageChange={(selected) => {
                        setpageNumber(selected.selected);
                        setPageChange(!pageChange);
                      }}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"page-item active"}
                      forcePage={pageNumber}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
}
