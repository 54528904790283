import React, { Component } from "react";

export default class DisconnectSocket extends Component {
  constructor(props) {
    super(props);
  }
  componentWillUnmount() {
    this.props.socket.on("disconnect", (data) => {
      console.log("bhjkfhdgjkhdfkghkljk;lfghkljfgjh;lkfgl", data);
    });
  }
  render() {
    return <div></div>;
  }
}
