import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { BiBlock } from "react-icons/bi";
import { RiPinDistanceLine } from "react-icons/ri";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";

import { toastError, toastSuccess, loading } from "../../utlis/common";
import {
  specialUserList,
  blockUser,
  sendLoginEmail,
} from "../../networkRequests/user";
import { userPerPage } from "../../utlis/appConstants";
import { Code } from "react-content-loader";
import { useModal } from "../../context/modalState";
import { openModel } from "../../utlis/appConstants";
import ChangeCompany from "../modals/ChangeCompany";
import ChangePriceRange from "../modals/ChangePriceRange";

export default function SpecialUserList() {
  const history = useHistory();
  const { modelState, setModelState } = useModal();
  const [search, setSearch] = useState("");
  const [pageNumber, setpageNumber] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [pageChange, setPageChange] = useState(false);
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [selected, setSelected] = useState("name");
  const [selectedUser, setSelectedUser] = useState("");
  const [userPerPageCount, setUserPerPageCount] = useState(25);

  const pageCount = Math.ceil(userCount / userPerPageCount);

  const _specialUserList = async () => {
    try {
      setLoader(true);
      const res = await specialUserList(userPerPageCount, pageNumber, search);
      console.log(res);
      setUsers(res.data.users);
      setUserCount(res.data.count);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error.message);
    }
  };

  const _blockUser = async (userId) => {
    try {
      setLoader(true);
      const res = await blockUser(userId);
      toastSuccess(res.message);
      setPageChange(!pageChange);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const _sendLoginEmail = async (userId) => {
    try {
      setLoader(true);
      const res = await sendLoginEmail(userId);
      toastSuccess("Email sent successfully");
      setPageChange(!pageChange);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const sorting = (col) => {
    if (order === "ASC" || order === null) {
      const sorted = [...users].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setUsers(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...users].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setUsers(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    _specialUserList();
  }, [pageChange]);
  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Special Users</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="breadcrumb-item active">Special User</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {loader ? (
        <Code />
      ) : (
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#tranparent" }}
                  >
                    <h3 className="card-title"></h3>
                    <div className="card-tools">
                      <div
                        className="input-group border bg-white input-group-sm"
                        style={{ borderRadius: 3 }}
                      >
                        <input
                          type="text"
                          name="table_search"
                          className="form-control float-right border-0"
                          placeholder="Search"
                          value={search}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setpageNumber(0);
                              setPageChange(!pageChange);
                            }
                          }}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                        <div
                          className="input-group-append"
                          onClick={() => {
                            setpageNumber(0);
                            setPageChange(!pageChange);
                          }}
                        >
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th
                            onClick={() => {
                              sorting("name");
                              setSelected("name");
                            }}
                          >
                            Name &nbsp;
                            <img
                              src={
                                selected == "name"
                                  ? order == null
                                    ? ""
                                    : order == "ASC"
                                    ? "/dist/img/up-arrow.png"
                                    : "/dist/img/down-arrow.png"
                                  : ""
                              }
                              //className="brand-image img-circle elevation-3"
                              style={{ opacity: ".8" }}
                            />
                          </th>
                          <th
                            onClick={() => {
                              sorting("email");
                              setSelected("email");
                            }}
                          >
                            Email &nbsp;
                            <img
                              src={
                                selected == "email"
                                  ? order == null
                                    ? ""
                                    : order == "ASC"
                                    ? "/dist/img/up-arrow.png"
                                    : "/dist/img/down-arrow.png"
                                  : ""
                              }
                            />
                          </th>
                          <th>Company Name</th>
                          <th>Price Range</th>
                          <th>Status</th>
                          <th>Send Login Mail</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((m) => (
                          <tr key={m._id} style={{ cursor: "pointer" }}>
                            <td
                              onClick={() => {
                                history.push(`/user/${m._id}`);
                              }}
                            >
                              {m.name}
                            </td>
                            <td
                              onClick={() => {
                                history.push(`/user/${m._id}`);
                              }}
                            >
                              {m.email}
                            </td>
                            <td
                              onClick={() => {
                                history.push(`/user/${m._id}`);
                              }}
                            >
                              {m?.companyId?.name}
                            </td>
                            <td
                              onClick={() => {
                                history.push(`/user/${m._id}`);
                              }}
                            >
                              {m?.priceRange?.name}
                            </td>
                            <td>
                              {m.isBlocked ? (
                                <span className="badge bg-danger">Blocked</span>
                              ) : (
                                <span className="badge bg-success">Active</span>
                              )}
                            </td>
                            <td title="Send Mail">
                              <span
                                className="badge bg-danger"
                                onClick={() => {
                                  _sendLoginEmail(m._id);
                                }}
                              >
                                Send
                              </span>
                            </td>
                            <td
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <i
                                title="Block"
                                className="nav-icon fas "
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  _blockUser(m._id);
                                }}
                              >
                                <BiBlock />
                              </i>
                              <i
                                className="nav-icon far "
                                type="button"
                                title="Change Company"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedUser(m._id);
                                  setModelState(openModel.CHANGE_COMPANY);
                                }}
                              >
                                <CgArrowsExchangeAlt
                                  style={{ height: 25, width: 50 }}
                                />
                              </i>
                              <i
                                className="nav-icon far "
                                type="button"
                                title="Change Price Range"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedUser(m._id);
                                  setModelState(openModel.CHANGE_PRICE_RANGE);
                                }}
                              >
                                <RiPinDistanceLine />
                              </i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <ChangeCompany
                    user={selectedUser}
                    onPress={() => {
                      setPageChange(!pageChange);
                    }}
                  />
                  <ChangePriceRange
                    user={selectedUser}
                    onPress={() => {
                      setPageChange(!pageChange);
                    }}
                  />

                  {userCount >= 10 ? (
                    <div className="pagination-limit">
                      <ReactPaginate
                        previousLabel={<span aria-hidden="true">&laquo;</span>}
                        nextLabel={<span aria-hidden="true">&raquo;</span>}
                        breakLabel={"....."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        onPageChange={(selected) => {
                          setpageNumber(selected.selected);
                          setPageChange(!pageChange);
                        }}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"page-item active "}
                        forcePage={pageNumber}
                      />
                      <select
                        value={userPerPageCount}
                        style={{ width: "160px", minWidth: "100px" }}
                        className="custom-select"
                        onChange={(e) => {
                          setpageNumber(0);
                          setUserPerPageCount(e.target.value);
                          setPageChange(!pageChange);
                        }}
                      >
                        <option value="" disabled selected hidden>
                          Limit
                        </option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
