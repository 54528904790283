import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="main-footer">
        <strong>Copyright © 2014-2022.</strong>&nbsp; All rights reserved.
        <div className="float-right d-none d-sm-inline-block"></div>
      </footer>
    </div>
  );
}
