import React, { useRef, useState, useEffect } from "react";
import io from "socket.io-client";
import GoogleMapReact from "google-map-react";
import MyGreatPlace from "./my_great_place.jsx";
import DriverDetail from "./DriverDetail.jsx";
import { useModal } from "../../context/modalState";
import { openModel, baseUrl } from "../../utlis/appConstants";
import { getDriverLoc } from "../../networkRequests/driver";
import DisconnectSocket from "./DisconnectSocket";

let socket;

export default function DriverLoc() {
  const [data, setData] = useState([]);
  const [center, setCenter] = useState({
    lat: 20.5937,
    lng: 78.9629,
  });
  const [zoom, setZoom] = useState(7);
  const { modelState, setModelState } = useModal();
  const [selectedDriver, setSelectedDriver] = useState();

  const _getDriverLoc = async () => {
    try {
      const res = await getDriverLoc();
      res.data.map((d) => {
        let name;
        if (d.name.includes(" ")) {
          const words = d.name.split(" ");
          name = words[0][0].toUpperCase() + words[1][0].toUpperCase();
        } else {
          name =
            d.name[0].toUpperCase() + d.name[d.name.length - 1].toUpperCase();
        }
        d.sportName = name;
      });
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    socket = io(baseUrl, {
      query: `token=${localStorage.getItem("accessToken")}`,
    });
    socket.on("error", (err) => {
      console.log(err); // prints the message associated with the error
    });
  }, [baseUrl]);
  console.log(data);
  useEffect(() => {
    socket.on("receiveLatLng", (data) => {
      console.log(data, "d5555555555555555555555555jghkjhfhdgk");
      data.map((d) => {
        let name;
        if (d.name.includes(" ")) {
          const words = d.name.split(" ");
          name = words[0][0].toUpperCase() + words[1][0].toUpperCase();
        } else {
          name =
            d.name[0].toUpperCase() + d.name[d.name.length - 1].toUpperCase();
        }
        d.sportName = name;
      });
      setData(data);
    });
  });

  useEffect(() => {
    _getDriverLoc();
  }, []);

  useEffect(() => {
    if (center.lat === 20.5937 && data.length) {
      setCenter({ lat: data[0].lat, lng: data[0].lng });
    }
  }, [data]);

  useEffect(() => {
    return () => {
      setSelectedDriver(null);
      setModelState(openModel.NON);
      console.log("cleaned up");
    };
  }, []);

  return (
    <div style={{ height: "45vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
        center={center}
        defaultZoom={zoom}
        //yesIWantToUseGoogleMapApiInternals
        onChildClick={(e, value) => {
          // const index = data.findIndex(m => m.get('id') === markerId);
          // console.log(data[e], value);
          setSelectedDriver(data[e]);
          setModelState(openModel.DRIVER_LOC);
        }}
      >
        {data.map((place) => (
          <MyGreatPlace
            lat={place.lat}
            lng={place.lng}
            text={place.sportName}
          />
        ))}
      </GoogleMapReact>
      <DisconnectSocket socket={socket} />
      <DriverDetail data={selectedDriver} />
    </div>
  );
}
